<template>
    <v-dialog v-model="dialog" scrollable persistent max-width="600px" transition="dialog-transition">
        <template v-slot:activator="{ on }">
            <v-btn color="#0B3F67" dark v-on="on">
                Agregar
                <v-icon right>mdi-plus-box-outline</v-icon>
            </v-btn>
        </template>
        <v-card :loading="loading">
            <v-card-title class="pa-0">
              <ModalTitle :titulo="titulo" @cancelar="cancelar"/>
            </v-card-title>  
            <v-divider></v-divider>
            <v-card-text>
                <v-row>
                    <v-col cols="12">
                        <v-text-field 
                            v-model="promocion.interesado"
                            label="Interesado"
                            :error-messages="interesadoErrors"
                            @input="$v.promocion.interesado.$touch()"
                            @blur="$v.promocion.interesado.$touch()"
                            counter="100"
                            required>
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" v-if="anioConsulta < 2022">
                        <v-text-field
                            v-model="categoria"
                            :hint="categoriaHint"
                            :label="categoriaLabel"
                            :error-messages="categoriaErrors"
                            @input="$v.categoria.$touch()"
                            @blur="$v.categoria.$touch()"
                            counter="100"
                            required>
                        </v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <v-textarea
                            label="Descripción de Actividad"
                            v-model="promocion.descripcionActividad"
                            outlined
                            counter="500"
                            :error-messages="descripcionErrors"             
                            @change="$v.promocion.descripcionActividad.$touch()"
                            @blur="$v.promocion.descripcionActividad.$touch()"
                            required>
                        </v-textarea>  
                    </v-col> 
                    <v-col cols="12">
                        <v-menu
                            v-model="menu"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition">
                            offset-y
                            min-width="290px">
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    v-model="fechaMoment"
                                    label="Fecha de Actividad"
                                    prepend-inner-icon="mdi-calendar-month"
                                    readonly
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker 
                                v-model="fechaActividad" 
                                locale="es-CR" 
                                @input="menu = false">
                            </v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col cols="12" md="6" class="text-right subtitle-1" align-self="center">
                        <span>¿La parte interesada ha obtenido el galardón PBAE anteriormente?</span>
                    </v-col>
                    <v-divider vertical></v-divider>
                    <v-col cols="6" md="6" class="pt-0 pb-0">
                        <v-radio-group v-model="obtuvoGalardon"                            
                            validate-on-blur>
                            <v-radio label="Si" :value="true"></v-radio>
                            <v-radio label="No" :value="false"></v-radio>
                        </v-radio-group> 
                    </v-col>
                    <v-col cols="12" md="6" v-if="obtuvoGalardon">
                        <v-select
                            :items="categorias"
                            label="Categoría PBAE"
                            v-model="promocion.categoriaPbae"
                            :error-messages="categoriaPbaeErrors"             
                            @change="$v.promocion.categoriaPbae.$touch()"
                            @blur="$v.promocion.categoriaPbae.$touch()"
                            required>
                        </v-select>  
                    </v-col>
                    <v-col cols="12" md="6" v-if="obtuvoGalardon">
                        <v-text-field
                            label="Número de Registro"
                            v-model="promocion.numeroRegistro"
                            :error-messages="numeroRegistroErrors"             
                            @change="$v.promocion.numeroRegistro.$touch()"
                            @blur="$v.promocion.numeroRegistro.$touch()">
                        </v-text-field>  
                    </v-col>
                    <v-col cols="12" class="pt-0 pb-0" v-if="tipo==3">
                        <v-select
                            label="Relación con la Organización"
                            :items="participantes"
                            item-text="nombre"
                            item-value="id"
                            multiple
                            chips
                            v-model="listParticipantes"
                            :error-messages="ParticipanteErrors"             
                            @change="$v.listParticipantes.$touch()"
                            @blur="$v.listParticipantes.$touch()"
                            required
                        ></v-select>
                    </v-col>
                    <v-col cols="12">
                        <v-file-input label="Adjuntar Evidencias"
                            accept=".png, .jpeg, .jpg"
                            v-model="evidencias"
                            show-size 
                            multiple                               
                            clearable
                            :error-messages="evidenciasErrors"             
                            @change="$v.evidencias.$touch()"
                            @blur="$v.evidencias.$touch()">
                        </v-file-input>
                    </v-col>
                    <v-col cols="12" v-if="anioConsulta > 2021">
                        <v-file-input label="Lista de Asistencia"
                            accept=".png, .jpeg, .jpg"
                            v-model="asistencia"
                            show-size 
                            multiple                               
                            clearable
                            :error-messages="asistenciaErrors"             
                            @change="$v.asistencia.$touch()"
                            @blur="$v.asistencia.$touch()">
                        </v-file-input>
                    </v-col>
                    <v-col cols="12" v-if="anioConsulta > 2021">
                        <v-file-input label="Otras"
                            accept=".png, .jpeg, .jpg"
                            v-model="otrasActividad"
                            show-size 
                            multiple                               
                            clearable
                            :error-messages="evidenciasOtrasErrors"             
                            @change="$v.otrasActividad.$touch()"
                            @blur="$v.otrasActividad.$touch()">
                        </v-file-input>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text :disabled="loading" color="error" @click="cancelar">Cancelar</v-btn>
                <v-btn text :loading="loading" color="#0B3F67" @click="agregarEstrella">Agregar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import moment from "moment"
import axios from "@/axios";
import ModalTitle from '@/components/utils/ModalTitleComponent'
import { required, requiredIf, integer, maxLength } from "vuelidate/lib/validators";

const maxFileSize = (files) => {
    if(files != null) {
        let maximo = files.length * (1024 * 1024);
        let total = 0;
        files.forEach(file => {
            total += file.size
        });        
        return total < maximo;
    }
    return false;
}

export default {
    created() {
        axios.defaults.headers.common["Authorization"] = "Bearer " + this.$store.getters.token;
    },
    components: {
      ModalTitle
    },

    mounted(){
        this.getDataAgregar();
    },

    computed: {
        fileSize() {
            return this.evidencias != null ? this.evidencias.length : 0;
        },
        otrasSize(){
            return this.otrasActividad != null ? this.otrasActividad.length : 0;
        },
        asistenciaSize(){
            return this.asistencia != null ? this.asistencia.length : 0;
        },
        idUsuario() {
            return this.$store.getters.usuario.id;
        },
        idInstalacion() {
            return this.$store.getters.sucursal.id;
        },
        fechaMoment () {
            return this.fechaActividad ? moment(this.fechaActividad).format('DD/MM/YYYY') : '';
        },
        interesadoErrors() {
            const errors = []
            if (!this.$v.promocion.interesado.$dirty) return errors            
            !this.$v.promocion.interesado.required && errors.push('El nombre del ente interesado es requerido.')
            !this.$v.promocion.interesado.maxLength && errors.push('No debe exceder el tamaño permitido.')
            return errors
        },
        categoriaErrors() {
            const errors = []
            if (!this.$v.categoria.$dirty) return errors            
            !this.$v.categoria.required && errors.push('La categoría del interesado es requerida.')
            !this.$v.categoria.maxLength && errors.push('No debe exceder el tamaño permitido.')
            return errors
        },
        descripcionErrors() {
            const errors = []
            if (!this.$v.promocion.descripcionActividad.$dirty) return errors            
            !this.$v.promocion.descripcionActividad.required && errors.push('La descripción de la actividad es requerida.')
            !this.$v.promocion.descripcionActividad.maxLength && errors.push('No debe exceder el tamaño permitido.')
            return errors
        },
        categoriaPbaeErrors() {
            const errors = []
            if (!this.$v.promocion.categoriaPbae.$dirty) return errors
            !this.$v.promocion.categoriaPbae.required && errors.push('Campo requerido.')
            return errors
        },
        numeroRegistroErrors() {
            const errors = []
            if (!this.$v.promocion.numeroRegistro.$dirty) return errors            
            !this.$v.promocion.numeroRegistro.required && errors.push('El número de registro es requerido.')
            !this.$v.promocion.numeroRegistro.integer && errors.push('Solo debe digitar números.')
            return errors
        },
        evidenciasErrors () {
            const errors = []
            if (!this.$v.evidencias.$dirty) return errors            
            !this.$v.evidencias.required && errors.push('La evidencia es requerida.')
            !this.$v.evidencias.maxFileSize && errors.push('El tamaño total debe ser menor a ' + this.fileSize + 'MB.')        
            return errors
        },
        evidenciasOtrasErrors () {
            const errors = []
            if (!this.$v.otrasActividad.$dirty) return errors            
            !this.$v.otrasActividad.required && errors.push('La actividad es requerida.')
            !this.$v.otrasActividad.maxFileSize && errors.push('El tamaño total debe ser menor a ' + this.otrasSize + 'MB.')        
            return errors
        }, 
        asistenciaErrors() {
            const errors = []
            if (!this.$v.asistencia.$dirty) return errors            
            !this.$v.asistencia.required && errors.push('La lista de asistencia es requerida.')
            !this.$v.asistencia.maxFileSize && errors.push('El tamaño total debe ser menor a ' + this.asistenciaSize + 'MB.')        
            return errors
        }, 
        categoriaLabel() {
            return this.tipo === 3 ? 'Categoría Cadena de Valor' : 'Categoría Comunitaria';
        },
        tiempoPrograma() {
            return this.$store.getters.sucursal.tiempoEnPrograma;
        },
        categoriaHint() {
            return this.tipo === 3 ? 
                'Ej. Clientes, Proveedores' : 
                'Ej. Centros Educativos, Asociaciones de Desarrollo';
        },
        ParticipanteErrors () {
            const errors = []
            if (!this.$v.listParticipantes.$dirty) return errors
            !this.$v.listParticipantes.required && errors.push('Campo requerido')
            return errors
        },
        anioConsulta() {
            return this. $store.getters.anioConsulta;
        },
    },
    props: [
        'tipo',
        'titulo',
    ],
    data: () => ({
        dialog: false,
        loading: false,
        menu: false,
        promocion: {
            interesado: '',
            descripcionActividad: '',
            categoriaPbae: '',
            numeroRegistro: '',
        },
        fechaActividad: new Date().toISOString().substr(0, 10),
        categoria: '',
        evidencias: [],
        otrasActividad: [],
        asistencia: [],
        participantes: [],
        listParticipantes: [],
        obtuvoGalardon: false,
        categorias:[
            "Agropecuaria",
            "Bienestar Animal",
            "Biodiversidad",
            "Cambio Climático",
            "Centros Educativos",
            "Comunidad clima neutral",
            "Comunidades",
            "Construcción sostenible",
            "Eclesial - Ecologica",
            "Ecodiplomática",
            "Espacios Naturales Protegidos",
            "Eventos Especiales",
            "Hogares Sostenibles",
            "Microcuencas",
            "Movilidad sostenible",
            "Municipalidades",
            "Playas",
            "Promoción de Higiene",
            "Salud Comunitaria",
        ]
    }),
    validations: {
        promocion: {
            interesado: { required, maxLength: maxLength(100) },
            descripcionActividad: { required, maxLength: maxLength(500) },
            categoriaPbae: { required: requiredIf(function(){
                return this.obtuvoGalardon;
            }) },
            numeroRegistro: { required: requiredIf(function() { 
                return this.tiempoPrograma >= 2 && this.obtuvoGalardon;
            }), integer},
        },
        categoria: { required: requiredIf(function(){
            return this.anioConsulta < 2022;
        }), maxLength: maxLength(100) },
        evidencias: { required, maxFileSize },
        otrasActividad: {required, maxFileSize},
        asistencia: {required, maxFileSize},
        listParticipantes: {required: requiredIf(function(){
            return this.tipo == 3;
        })},
    },
    methods: {
        cancelar() {
            this.promocion = {};
            this.categoria = '';
            this.fechaActividad = new Date().toISOString().substr(0, 10);
            this.evidencias = [];
            this.otrasActividad = [];
            this.asistencia = [];
            this.listParticipantes = [];
            this.dialog = false;
            this.loading = false;
            this.$v.promocion.$reset();
            this.$v.evidencias.$reset();
            this.$v.otrasActividad.$reset();
            this.$v.asistencia.$reset();
            this.$v.categoria.$reset();
            this.$v.listParticipantes.$reset();
        },
        agregarEstrella() {
            this.$v.$touch();
            if (!this.$v.$invalid) {
                this.loading = true;
                if (this.tipo === 3)
                    this.promocionPbaeCadenaValor();
                else
                    this.promocionPbaeComunidad();
            }
        },
        promocionPbaeCadenaValor() {
            let formData = new FormData();
            this.evidencias.forEach(file => {
                formData.append('archivos', file);
            });
            this.otrasActividad.forEach(file2 =>{
                formData.append('evidActividad',file2);
            });
            this.asistencia.forEach(file3 =>{
                formData.append('evidParticipacion',file3);
            });
            formData.append("fecha", this.fechaActividad);
            formData.append("idInstalacion", this.idInstalacion);
            formData.append("idUsuario", this.idUsuario);
            formData.append("categoria", this.categoria);
            formData.append("promocionS", JSON.stringify(this.promocion));
            formData.append("participantes", this.listParticipantes);
            axios.post("/estrellas/agregaPromocadenaValor", formData)
            .then(response => {
                this.$store.commit('historialEstrella', response.data)
                this.efectivo();
            }).catch(error => {
              if (error.request && error.request.status === 401) {
                this.$store.commit('showSessionDialog', true);
              } else {
                if (error.request && error.request.status === 415) {
                  this.$store.dispatch('noti', {mensaje: error.response.data, color: 'error'});
                  this.loading = false;
                } else {
                  this.erroneo(error);
                }
              }
            });
        },
        promocionPbaeComunidad() {
            let formData = new FormData();
            this.evidencias.forEach(file => {
                formData.append('archivos', file);
            });
            this.otrasActividad.forEach(file2 =>{
                formData.append('evidActividad',file2);
            });
            this.asistencia.forEach(file3 =>{
                formData.append('evidParticipacion',file3);
            });
            formData.append("fecha", this.fechaActividad);
            formData.append("idInstalacion", this.idInstalacion);
            formData.append("idUsuario", this.idUsuario);
            formData.append("categoria", this.categoria);
            formData.append("promocionS", JSON.stringify(this.promocion));
            axios.post("/estrellas/agregaPromoComunidad", formData)
            .then(response => {
                this.$store.commit('historialEstrella', response.data)
                this.efectivo();
            }).catch(error => {
              if (error.request && error.request.status === 401) {
                this.$store.commit('showSessionDialog', true);
              } else {
                if (error.request && error.request.status === 415) {
                  this.$store.dispatch('noti', {mensaje: error.response.data, color: 'error'});
                  this.loading = false;
                } else {
                   this.erroneo(error);
                }
              }
            });
        },
        efectivo() {
            this.$emit("recargaTabla");
            this.$store.dispatch("noti", { mensaje: "Promoción PBAE agregada", color: "success" });
            this.cancelar();
        }, 
        erroneo(error) {
            console.log("Error al agregar promoción " + error);
            this.$store.dispatch("noti", { 
                mensaje: "Ocurrió un error no se agregó la promoción PBAE",
                color: "error"
            });
            this.cancelar();
        },

        getDataAgregar() {
            this.loading = true;
            axios.get('/estrellas/getDataAgregar')
            .then(response => {
                this.participantes = response.data.participantes;
            }).catch(err => {
                console.error(err);
            }).finally(() => {
                this.loading = false;
            })
        }
    }
};
</script>
